/* globals Swiper */
export default function () {
    function Settings() {
        this.slidesPerView = 5;
        this.slidesPerGroup = 5;
        this.spaceBetween = 11;
        this.roundLengths = true;
        this.simulateTouch = true;
        this.slideClass = 'c-goods__block';
        this.prevButton = '.goods_section__toggle-back';
        this.nextButton = '.goods_section__toggle-next';
        this.breakpoints = {
            1280: {
                slidesPerView: 4,
                spaceBetween: 11,
                slidesPerGroup: 4,
            },
            879: {
                slidesPerView: 3,
                spaceBetween: 7,
                slidesPerGroup: 3,
            },
            641: {
                slidesPerView: 1,
                spaceBetween: 7,
                slidesPerGroup: 3,
            },
            570: {
                slidesPerView: 1,
                spaceBetween: 7,
                slidesPerGroup: 1,
            },
            425: {
                slidesPerView: 1,
                spaceBetween: 7,
                slidesPerGroup: 1,
                slidesOffsetAfter: -204,
            },
            375: {
                slidesPerView: 1,
                spaceBetween: 7,
                slidesPerGroup: 1,
                slidesOffsetAfter: -161,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 7,
                slidesPerGroup: 1,
                slidesOffsetAfter: -102,
            },
        };
    }

    const settings1 = new Settings();
    const settings2 = new Settings();
    let list = {};

    settings2.breakpoints = {
        1280: {
            slidesPerView: 4,
            spaceBetween: 11,
            slidesPerGroup: 4,
        },
        879: {
            slidesPerView: 3,
            spaceBetween: 7,
            slidesPerGroup: 3,
        },
        641: {
            slidesPerView: 1,
            spaceBetween: 7,
            slidesPerGroup: 3,
        },
        570: {
            slidesPerView: 1,
            spaceBetween: 7,
            slidesPerGroup: 1,
        },
    };

    let $list = $('.goods_section_products');

    if ($list.length === 1) {
        // eslint-disable-next-line no-new
        new Swiper('.c-goods_swiper', settings1);
    }

    if ($list.length > 1) {
        let indexList = $list.length;

        // eslint-disable-next-line func-names
        $.each($list, function (i, elem) {
            let currentWrap = $(this);
            // let idName = $(element, item).attr('id');

            let currentSlider = $('.c-goods_swiper', elem);
            currentSlider.attr('id', `swiperBlock-${indexList}`);

            currentWrap.closest('.products').addClass(`products-${indexList}`);
            currentWrap.css('z-index', indexList);
            $(`.products-${indexList} .goods_section__toggle-back`).addClass(`swiperBlock-${indexList}`);
            $(`.products-${indexList} .goods_section__toggle-next`).addClass(`swiperBlock-${indexList}`);

            settings1.prevButton = `.products-${indexList} .goods_section__toggle-back.swiperBlock-${indexList}`;
            settings1.nextButton = `.products-${indexList} .goods_section__toggle-next.swiperBlock-${indexList}`;

            // eslint-disable-next-line no-new
            list[indexList] = new Swiper(`#swiperBlock-${indexList}`, settings1);

            indexList--;
        });
    }
}
