import '@babel/polyfill';
import svg4everybody from 'svg4everybody';
import Swiper from '../../node_modules/swiper/dist/js/swiper.js';
import PerfectScrollbar from 'perfect-scrollbar';
// import $ from 'jquery';

svg4everybody();

// window.$ = $;
// window.jQuery = $;
window.Swiper = Swiper;
window.PerfectScrollbar = PerfectScrollbar;

require('ninelines-ua-parser');
