export default function () {
    let updateTimeout;

    function recalc() {
        $('.compare__table .compare__row, .compare__attribute-item').removeAttr('style');

        // eslint-disable-next-line func-names
        $('.compare__table .compare__row').not('.compare__row--header').each(function (i) {
            const heightRow = $(this).outerHeight();
            const heightAttr = $('.compare__attribute-item').eq(i).outerHeight();
            const heightResult = Math.max(heightRow, heightAttr);

            $(this).css('height', `${heightResult}px`);
            $('.compare__attribute-item').eq(i).css('height', `${heightResult}px`);
        });

        $('.compare__attribute-item').addClass('show');
    }

    function updateAttributesHeight(delay = 200) {
        clearInterval(updateTimeout);
        updateTimeout = setTimeout(recalc, delay);
    }

    updateAttributesHeight(600);
    setTimeout(updateAttributesHeight, 1200);

    $(window).on('resize', () => {
        updateAttributesHeight();
    });
}
