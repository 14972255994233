/* globals PerfectScrollbar */
// import {addToCart} from '../cart';
import attributesHeight from '../compare/attributes-height';

function checkForScrollPosition() {
    const halfOfView = document.documentElement.clientHeight / 2;
    const tableLocation = $('.compare__table')[0].getBoundingClientRect();

    const offsetTop = -tableLocation.top + halfOfView;
    const offsetBottom = tableLocation.bottom - halfOfView;

    if (offsetTop < offsetBottom) {
        $('.ps__rail-x').addClass('to-top');
    } else {
        $('.ps__rail-x').removeClass('to-top');
    }
}

export default function () {
    attributesHeight();

    let $body = $('body');

    if ($('.compare__table-wrapper').length) {
        const compareScroll = new PerfectScrollbar('.compare__table-wrapper', {
            handlers: ['click-rail', 'drag-thumb', 'keyboard', 'touch', 'wheel'],
        });

        $body.on('touchmove', '.compare__table-wrapper', (e) => {
            if (e.isDefaultPrevented() && $(window).scrollTop() === 0) {
                $(window).scrollTop(1);
            }
        });

        $body.on('wheel', '.compare__table-wrapper', (e) => {
            compareScroll.element.scrollLeft = compareScroll.lastScrollLeft + e.originalEvent.deltaX;
        });

        checkForScrollPosition();

        $(window).on('resize', () => {
            compareScroll.update();
            checkForScrollPosition();
        });

        $(window).on('scroll', () => {
            checkForScrollPosition();
        });
    }

    // eslint-disable-next-line func-names
    $body.on('click', '.compare__buy', function (e) {
        e.preventDefault();
        // addToCart($(this).attr('data-product-id'));
    });
}
